/** @format */

.onboarding-graphic {
  background: linear-gradient(111.92deg, #ffeacd 23.44%, #daeef8 82.79%);
}
.moveHorizontal {
  transform: rotateX(180deg) translateX(22px);
}
.sparkleFlipped {
  transform: rotateX(180deg) rotateZ(270deg);
}
