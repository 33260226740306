/** @format */
/* These classes recreate input's placeholder behaviour for our contenteditable
implementation. Use our custom `.placeholder-text-*` classes to add colors */
[aria-placeholder].empty:before {
  content: attr(aria-placeholder);
  pointer-events: none;
}

[aria-placeholder].empty {
  position: relative;
  /* That should be covered by the placeholder styles */
  /* opacity: 0.5; */
}

[aria-placeholder].empty:before {
  position: absolute;
  /* These aren't necessary, and disabling them fixes positioning when the text
  area has padding */
  /* top: 0;
  left: 0; */
  max-width: 90%;
}

[aria-placeholder].empty.truncatedPlaceholder:before {
  /* Truncate long placeholders so they don't fly out of their bounds (grid
  item/script editor)  */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
